<template>
  <section>
    <b-row>
      <b-col v-if="centerAnalyses && centerAnalyses.length > 0" cols="12">
        <div class="m-2">
          <b-row>
            <b-col
              v-for="(item, index) in centerAnalyses"
              :key="index"
              cols="12"
              md="4"
              class="py-0 px-md-1 px-0"
            >
              <b-card no-body>
                <div class="card-analyse">
                  <p>اسم التحليل</p>

                  <button
                    @click="
                      itemAnalyses == null
                        ? (itemAnalyses = item)
                        : (itemAnalyses =
                            null || itemAnalyses == item
                              ? (itemAnalyses = null)
                              : (itemAnalyses = item))
                    "
                    class="btn show-actions-list"
                  >
                    <dots-3-icon />
                  </button>
                  <div
                    class="actions-list"
                    v-if="itemAnalyses && itemAnalyses.id === item.id"
                  >
                    <button class="btn action-item-btn" @click="edit()">
                      <edit-icon />
                      تعديل
                    </button>
                    <button
                      class="btn action-item-btn"
                      @click="showStatusAnalysesModal = !showStatusAnalysesModal"
                    >
                      <stop-icon />
                      {{ itemAnalyses.status == 1 ? "ايقاف" : "تنشيط" }}
                    </button>
                  </div>
                  <h3 class="mt-2">{{ item.title }}</h3>
                  <div class="d-flex justify-content-between align-items-end">
                    <h3 class="d-flex justify-content-center align-items-end">
                    </h3>
                    <span class="item-active" v-if="item.status == 1">نشط</span>
                    <span class="item-disable" v-if="item.status == 2">موقف</span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-if="itemAnalyses"
      :title="itemAnalyses.status == 1 ? 'ايقاف التحليل' : 'تنشيط التحليل'"
      v-model="showStatusAnalysesModal"
      hide-footer
      centered
    >
      <div class="profile">
        <div class="header-content p-0" style="min-height: auto">
          <div class="header-content-form p-0">
            <b-row class="align-items-center w-100 m-0 text-center">
              <b-col lg="12" class="py-5">
                <h2>
                  {{
                    itemAnalyses.status == 1
                      ? "تاكيد ايقاف التحليل"
                      : "تاكيد تنشيط التحليل"
                  }}
                </h2>
                <p>
                  {{
                    itemAnalyses.status == 1
                      ? "لن يظهر التحليل في تفاصيلك على الموقع ولن يتمكن العملاء من حجز التحليل"
                      : "سوف يظهر التحليل في تفاصيلك على الموقع وسوف يتمكن العملاء من حجز التحليل"
                  }}
                </p>
              </b-col>
              <b-col
                lg="12"
                class="my-1 px-0 d-lg-flex d-sm-contents justify-content-between"
              >
                <b-button
                  class="filter-main-btn"
                  @click="showStatusAnalysesModal = false"
                >
                  إلـــــــغــــــاء
                </b-button>
                <b-button class="add-main-btn" @click="changeStatusAnalyses">
                  {{ itemAnalyses.status == 1 ? "ايقاف" : "تنشيط" }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "../apexChartData";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    VueApexCharts,
  },
  data() {
    return {
      apexChatData,
      showStatusAnalysesModal: false,
      itemAnalyses: null,
      centerAnalyses: [],
    };
  },
  computed: {
    ...mapGetters({
      getAnalyses: "getAnalyses",
    }),
  },
  watch: {
    getAnalyses(data) {
      if (data) {
        this.centerAnalyses = data;
      }
    },
  },
  created() {},
  methods: {
    ...mapActions({
      loadAnalyses: "loadAnalyses",
      changeAnalysesStatus: "changeAnalysesStatus",
    }),
    edit() {
      if (this.itemAnalyses) {
        this.$router.push({
          name: "settings-edit-analyzes",
          params: { id: this.itemAnalyses.id },
        });
      }
    },
    showDescription() {},
    changeStatusAnalyses() {
      if (this.itemAnalyses) {
        this.changeAnalysesStatus(this.itemAnalyses.id).then((res) => {
          {
            this.itemAnalyses = null;
            this.showStatusAnalysesModal = false;
            this.loadAnalyses();
          }
        });
      }
    },
  },
  mounted() {
    this.loadAnalyses();
  },
};
</script>
